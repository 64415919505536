<template>
  <w-tab>
    <w-tab-pane :label="$t('TEMPLATES.TEMPLATES')" name="templates" :to="{name: 'templates-templates'}"></w-tab-pane>
    <w-tab-pane :label="$t('TEMPLATES.DRAFTS')" name="drafts" :to="{name: 'templates-drafts'}"></w-tab-pane>
  </w-tab>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class TemplateTab extends Vue {

}
</script>
