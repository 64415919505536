



















































































	import { Vue, Component } from 'vue-property-decorator'
	import { namespace, Action, State } from 'vuex-class'
	import { mapActions, mapState } from 'vuex'
	import Tab from '@/components/template/Tab.vue'
	// const templates = namespace('templates')
	// const deployment = namespace('deployment')

	interface ITemplate {
		create_time: number
		developer: string
		source_miniprogram: string
		source_miniprogram_appid: string
		template_id: number
		user_desc: string
		user_version: string
	}

	@Component({
		components: { Tab },
		methods: {
			...mapActions([
				"fetchDrafts",
				"fetchTemplates",
				"addToTemplates",
				"submitCodeForAll",
				"uploadCodeForAll",
				"deleteTemplate"
			])
		}
	})
	export default class Templates extends Vue {

		fetchTemplates: () => any
		submitCodeForAll: () => any
		uploadCodeForAll: (params: any) => any
		deleteTemplate: (templateId: string) => any

		private currentTabIndex: string = 'drafts'

		private uploading: boolean = false
    private tableLoading: boolean = true
		private showErrorList: boolean = false

		@State(state => state.deployment.uploadFailedList) uploadFailedList: any

		@State(state => state.templates.drafts) drafts: any

		@State(state => state.templates.templates) private templates: any

		@State(state => state.i18n.locale) locale: string


		closeErrorList () {
			this.showErrorList = false
			this.uploading = false
		}

		tapContinue () {
			this.doSubmitCodeForAll()
		}

		async doSubmitCodeForAll () {
			await this.submitCodeForAll()
			// if (this.uploadFailedList.length > 0) {
			// 	this.showErrorList = true
			// }
			this.$toast.success('Submit successfully', {decuration: 2000})
			this.uploading = false
		}

		async tapDeleteTemplate (templateId: string) {
			await this.deleteTemplate(templateId)
			this.$toast.success('Delete template successfully', {decuration: 1000})
			this.fetchTemplates()
		}

		async tapSubmitCode (template: ITemplate) {
			this.uploading = true
			const params = {
				piwikSiteId: process.env.VUE_APP_SERVER == 'production' ? '10' : '11',
				funDebug: process.env.VUE_APP_SERVER == 'production' ? 'production' : 'development',
				templateId: template.template_id,
				userVersion: template.user_version,
				userDesc: template.user_desc
			}
			await this.uploadCodeForAll(params)
			// if (this.uploadFailedList.length === 0) {
			// 	this.doSubmitCodeForAll()
			// } else {
			// 	this.showErrorList = true
			// }
			this.doSubmitCodeForAll()
		}

		async mounted () {
      await this.fetchTemplates()
      this.tableLoading = false
			// this.currentTabIndex = Number(this.$route.query.current) || 0
		}
	}
